import { PropsWithChildren, ReactNode } from 'react';
import { ParsedFile, UploadingPlotStatus } from '../../shared/types';
import {
  Text,
  Grid,
  Icon,
  Loader,
  Flex,
  Button,
  List,
} from '@liveeo/component-library';
import { humanReadableFileSize } from '@liveeo/helpers';
import { useTranslation } from 'react-i18next';
import classes from './SupplierOnboarding.module.css';

type Props = {
  plotUploadStatus: ParsedFile[];
  plotsWithErrors: ParsedFile[];
  resetUploader: () => void;
  isButtonDisabled: boolean;
};

export const UploadResults = ({
  plotUploadStatus,
  plotsWithErrors,
  resetUploader,
  isButtonDisabled,
}: Props) => {
  const { t } = useTranslation();

  const iconByStatus = {
    STANDBY: <Loader color="blue" size="xs" />,
    DONE: <Icon icon="circle-check" color="green" />,
    ERROR: <Icon icon="exclamation-circle" color="red" />,
  } as Record<UploadingPlotStatus, any>;

  return (
    <>
      {[...plotUploadStatus, ...plotsWithErrors].map((plot: ParsedFile, i) => (
        <Grid key={i} m={0}>
          <Grid.Col span={8}>
            <div className={classes.container}>
              <Text className={classes.text} truncate>
                {plot.filename}
              </Text>
              <List>
                {plot.errors?.map((errorComponent: ReactNode, i: number) => (
                  <List.Item key={i} c="red" className={classes.text}>
                    {errorComponent}
                  </List.Item>
                ))}
              </List>
            </div>
          </Grid.Col>
          <Grid.Col span={3}>
            <Text className={classes.text}>
              {humanReadableFileSize(plot.fileSize)}
            </Text>
          </Grid.Col>
          <Grid.Col span={1}>
            <Flex<PropsWithChildren<any>> align="center" justify="flex-end">
              {iconByStatus[plot.status]}
            </Flex>
          </Grid.Col>
        </Grid>
      ))}
      {!!plotsWithErrors.length && (
        <Flex<PropsWithChildren<any>> align="center" justify="flex-end">
          <Button w={150} disabled={isButtonDisabled} onClick={resetUploader}>
            {t('common.again')}
          </Button>
        </Flex>
      )}
    </>
  );
};
