import React, { memo } from 'react';
import {
  Icon,
  StyledTooltip,
  Text,
  ActionIcon,
} from '@liveeo/component-library';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';
import { DRAW_POLYGON } from '../../common/constants';
import classes from './DrawPolygonButton.module.css';

type ButtonHeaderProps = {
  mode: string | undefined;
};

export const DrawPolygonButton = memo(({ mode }: ButtonHeaderProps) => {
  const { t } = useTranslation();
  const location = useLocation();

  return (
    <div className={classes['btn-container']}>
      {mode === DRAW_POLYGON ? (
        <StyledTooltip
          label={
            <Text c="colors.black400">
              {t<string>('plots.draw.instructions.text')}
            </Text>
          }
          position="bottom"
          multiline
          style={{ width: 220 }}
          withArrow
          transitionProps={{ transition: 'fade', duration: 200 }}
          dark
        >
          <ActionIcon
            component={Link}
            to={`/map/plots/${location.search}`}
            replace
            className={classes['icon-container']}
          >
            <Icon icon="xmark" color="dark.0" />
          </ActionIcon>
        </StyledTooltip>
      ) : (
        <ActionIcon
          component={Link}
          replace
          to={`/map/plots/draw-plot${location.search}`}
          className={classes['icon-container']}
        >
          <div className={classes['draw-icon']} />
        </ActionIcon>
      )}
    </div>
  );
});
