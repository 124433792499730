import { PropsWithChildren } from 'react';
import * as yup from 'yup';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from '@mantine/hooks';
import { useTracking, useBusiness, useUser } from '../../hooks';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Onboarder } from '../../shared/types';
import { countryList } from '../../../assets/countryList';

import {
  Stack,
  TextInput,
  Group,
  Button,
  GenericErrorMessage,
  Logo,
  Flex,
  Title,
  Text,
  Select,
  SimpleGrid,
  Divider,
} from '@liveeo/component-library';

import classes from './AccountDetails.module.css';

type Props = {
  nextStep: () => void;
};

const UpdateUserSchema = yup.object({
  firstName: yup.string().required(),
  lastName: yup.string().required(),
  name: yup.string().required(),
  addressLine1: yup.string().required(),
  addressLine2: yup.string(),
  addressCity: yup.string().required(),
  addressPostcode: yup.string().required(),
  addressCountry: yup
    .string()
    .oneOf(countryList.map((country) => country.label))
    .required(),
});

export const AccountDetails = ({ nextStep }: Props) => {
  const { t } = useTranslation();
  const { data: user, updateUser } = useUser();
  const { updateBusiness } = useBusiness(user?.business?.id);
  const largeScreen = useMediaQuery('(min-width: 992px)');
  const { trackEvent } = useTracking();
  const businessName = () => {
    if (user?.business?.newlyCreated) {
      return '';
    }
    return user?.business?.name;
  };

  const {
    register,
    handleSubmit,
    control,
    formState: { errors, isValid, isDirty },
  } = useForm<Onboarder>({
    defaultValues: {
      firstName: user?.firstName || '',
      lastName: user?.lastName || '',
      name: businessName() || '',
      addressLine1: user?.business?.addressLine1 || '',
      addressLine2: user?.business?.addressLine2 || '',
      addressCity: user?.business?.addressCity || '',
      addressPostcode: user?.business?.addressPostcode || '',
      addressCountry: user?.business?.addressCountry || '',
    },
    resolver: yupResolver(UpdateUserSchema),
  });

  const onSubmit = async (data: Onboarder) => {
    try {
      isDirty &&
        (await Promise.all([
          updateUser({
            firstName: data.firstName,
            lastName: data.lastName,
          }),
          updateBusiness({
            name: data.name,
            addressLine1: data.addressLine1,
            addressLine2: data.addressLine2,
            addressCity: data.addressCity,
            addressPostcode: data.addressPostcode,
            addressCountry: data.addressCountry,
            addressCountryCode: countryList.find(
              (country) => country.label === data.addressCountry
            )?.value,
          }),
        ]));
      trackEvent('Onboarding', {
        step: 'account-details-form',
        action: 'submit',
      });
      nextStep();
    } catch (err) {
      console.error(`Completing profile - ${err}`);
    }
  };

  return (
    <GenericErrorMessage>
      <Flex<PropsWithChildren<any>> align="center">
        {!largeScreen && <Logo variant="submark" width={35} color="black" />}
      </Flex>
      <Stack mih={120} gap="xs">
        <Title order={2} fw={600} className={classes.label}>
          {t('profile.onboarding.steps.account.title')}
        </Title>
        <Text fw={400} className={classes.label}>
          {t('profile.onboarding.text')}
        </Text>
      </Stack>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Flex direction="column">
          <Stack>
            <SimpleGrid cols={{ base: 2, sm: 1, lg: 2 }} mb="xl">
              <TextInput
                {...register('firstName')}
                classNames={{
                  label: classes.label,
                  input: classes.input,
                }}
                label={t('profile.onboarding.steps.account.fields.first')}
                error={errors?.firstName?.message}
                withAsterisk
              />
              <TextInput
                {...register('lastName')}
                classNames={{
                  label: classes.label,
                  input: classes.input,
                }}
                label={t('profile.onboarding.steps.account.fields.last')}
                error={errors?.lastName?.message}
                withAsterisk
              />
            </SimpleGrid>
            <Divider />
          </Stack>
          <Stack my="xl">
            <TextInput
              {...register('name')}
              classNames={{
                label: classes.label,
                input: classes.input,
              }}
              label={t('profile.onboarding.steps.account.fields.company')}
              error={errors?.name?.message}
              withAsterisk
              w="48.5%"
            />
            <SimpleGrid cols={{ base: 2, sm: 1, lg: 2 }}>
              <TextInput
                {...register('addressLine1')}
                classNames={{
                  label: classes.label,
                  input: classes.input,
                }}
                label={t(
                  'profile.onboarding.steps.account.fields.addressLine1'
                )}
                error={errors?.addressLine1?.message}
                withAsterisk
              />
              <TextInput
                {...register('addressLine2')}
                classNames={{
                  label: classes.label,
                  input: classes.input,
                }}
                label={t(
                  'profile.onboarding.steps.account.fields.addressLine2'
                )}
                error={errors?.addressLine2?.message}
              />
            </SimpleGrid>
            <SimpleGrid cols={{ base: 2, sm: 1, lg: 2 }}>
              <TextInput
                {...register('addressCity')}
                classNames={{
                  label: classes.label,
                  input: classes.input,
                }}
                label={t('profile.onboarding.steps.account.fields.city')}
                error={errors?.addressCity?.message}
                withAsterisk
              />
              <Group grow>
                <TextInput
                  {...register('addressPostcode')}
                  classNames={{
                    label: classes.label,
                    input: classes.input,
                  }}
                  label={t('profile.onboarding.steps.account.fields.postcode')}
                  error={errors?.addressPostcode?.message}
                  withAsterisk
                />
                <Controller
                  name="addressCountry"
                  control={control}
                  render={({ field }) => (
                    <Select
                      {...field}
                      classNames={{
                        label: classes.label,
                        input: classes.input,
                        options: classes.options,
                        option: classes.hoverOption,
                      }}
                      label={t(
                        'profile.onboarding.steps.account.fields.country'
                      )}
                      data={countryList.map(({ label }) => ({
                        value: label,
                        label: t<string>(label),
                      }))}
                      error={errors?.addressCountry?.message}
                      withAsterisk
                      searchable
                      clearable
                    />
                  )}
                />
              </Group>
            </SimpleGrid>
          </Stack>
          <Group justify="flex-end">
            <Button type="submit" disabled={!isValid}>
              {t('common.next')}
            </Button>
          </Group>
        </Flex>
      </form>
    </GenericErrorMessage>
  );
};
