import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';
import { CreateSurveyResponseDto, SurveyResponse } from '../shared/types';
import { useFetchApi } from './useFetchApi';
import { useNotification } from './useNotification';

type FullSurveyResponse = SurveyResponse &
  Required<Pick<SurveyResponse, 'respondent' | 'survey'>>;

export const useSurveyResponses = (surveyId?: string) => {
  const fetch = useFetchApi();
  const queryClient = useQueryClient();
  const { apiError, successfullyCreated } = useNotification();
  const navigate = useNavigate();

  const { data, isLoading, isFetching } = useQuery({
    queryKey: ['survey-responses'],
    queryFn: () =>
      fetch('survey-responses?includes=respondent,survey') as Promise<
        FullSurveyResponse[]
      >,
    // queryFn: fakeSurveyResponsesFetch,
    onError: apiError,
    refetchOnWindowFocus: false,
  });

  const createMutation = useMutation({
    mutationKey: ['survey-responses'],
    mutationFn: (payload: CreateSurveyResponseDto) =>
      fetch(`surveys/${surveyId}/survey-responses`, {
        method: 'POST',
        body: JSON.stringify(payload),
      }) as Promise<SurveyResponse>,
  });

  const createSurveyResponse = (payload: CreateSurveyResponseDto) =>
    createMutation.mutate(payload, {
      onSuccess: (surveyResponse) => {
        successfullyCreated('survey response'); // TODO TEUDR-1696 fix notifications (particularly localization)
        queryClient.setQueryData(
          ['survey-responses'],
          (prev: SurveyResponse[] | undefined) =>
            prev && [surveyResponse, ...prev]
        );
        queryClient.setQueryData(['survey-response'], surveyResponse);
        queryClient.invalidateQueries({ queryKey: ['survey-responses'] });
        navigate(
          {
            pathname: `/documents/${surveyResponse.id}`,
          },
          { replace: true }
        );
      },
      onError: apiError,
    });

  return {
    data,
    isLoading,
    isFetching,
    createSurveyResponse: {
      execute: createSurveyResponse,
      isLoading: createMutation.isLoading,
    },
  };
};

// TODO TEUDR-1088 remove. currently useful for development without backend
const fakeSurveyResponsesFetch = async () => {
  return [
    {
      id: 'sr-12345678',
      countryCode: 'DE',
      respondentId: '12345678',
      survey: {
        id: '1234567',
        name: 'CMS Risk Assessment',
      },
      respondent: {
        id: '7d66043a-8693-48f6-9bdd-e53bf76518b0',
        name: 'Orion Global Sourcing',
      },
      status: 'DRAFT',
      surveyId: '1234567',
      json: {},
      createdAt: '2024-11-22T14:07:58Z',
      updatedAt: '2024-11-22T14:07:58Z',
    },
  ] as FullSurveyResponse[];
};
