import { PropsWithChildren } from 'react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from '@mantine/hooks';
import { useTracking, useUser } from '../../hooks';
import { BulkUploadFiles } from './BulkUploadFiles';
import { ParsedFile } from '../../shared/types';
import {
  Button,
  Flex,
  GenericErrorMessage,
  Group,
  HoverCard,
  List,
  Logo,
  ScrollArea,
  Stack,
  Text,
  Title,
  UnstyledButton,
} from '@liveeo/component-library';
import classes from './BulkUploadPlots.module.css';

type Props = {
  prevStep: () => void;
  plotsWithErrors: ParsedFile[];
  setPlotsWithErrors: (f: ParsedFile[]) => void;
  successfulUploads: ParsedFile[];
  setSuccessfulUploads: (f: ParsedFile[]) => void;
  showUploader: boolean;
  setShowUploader: (b: boolean) => void;
};

const Header = () => {
  const { t } = useTranslation();
  const largeScreen = useMediaQuery('(min-width: 992px)');

  return (
    <Flex<PropsWithChildren<any>>
      h={50}
      align="center"
      justify={largeScreen ? 'flex-end' : 'space-between'}
    >
      {!largeScreen && <Logo variant="submark" width={35} color="black" />}
      <Group gap="xs">
        <Text fw={400} className={classes.text}>
          {t('profile.onboarding.steps.import.trouble')}
        </Text>
        <HoverCard width={330} shadow="md" withArrow>
          <HoverCard.Target>
            <Text fw={900} className={classes.text} c="#805BA0">
              {t('profile.onboarding.tips.help.tooltip')}
            </Text>
          </HoverCard.Target>
          <HoverCard.Dropdown bg="white">
            <Stack c="#1F1D22">
              <Text size="sm" fw={600}>
                {t('profile.onboarding.tips.help.title')}
              </Text>
              <List size="sm" type="ordered" className={classes.text}>
                <List.Item>
                  {t('profile.onboarding.tips.help.list.one')}
                </List.Item>
                <List.Item>
                  {t('profile.onboarding.tips.help.list.two')}
                </List.Item>
                <List.Item>
                  {t('profile.onboarding.tips.help.list.three')}
                </List.Item>
                <List.Item>
                  {t('profile.onboarding.tips.help.list.four')}
                </List.Item>
              </List>
              <Text size="sm">{t('profile.onboarding.tips.help.text')}</Text>
            </Stack>
          </HoverCard.Dropdown>
        </HoverCard>
      </Group>
    </Flex>
  );
};

export const BulkUploadPlots = ({
  prevStep,
  plotsWithErrors,
  setPlotsWithErrors,
  successfulUploads,
  setSuccessfulUploads,
  showUploader,
  setShowUploader,
}: Props) => {
  const { t } = useTranslation();
  const { completeOnboarding } = useUser();
  const [isButtonDisabled, setIsButtonDisabled] = useState<boolean>(false);
  const { trackEvent } = useTracking();

  return (
    <GenericErrorMessage>
      <Header />
      <Stack mih={120} gap="xs">
        <Title order={2} fw={600} className={classes.text}>
          {t('profile.onboarding.steps.import.title')}
        </Title>
        <Text fw={400} className={classes.text}>
          {t('profile.onboarding.steps.import.text')}
        </Text>
      </Stack>
      <Flex<PropsWithChildren<any>>
        h={450}
        direction="column"
        justify="space-between"
      >
        <Stack gap="xs">
          <ScrollArea h={258} scrollbarSize={4} scrollHideDelay={0}>
            <BulkUploadFiles
              plotsWithErrors={plotsWithErrors}
              setPlotsWithErrors={setPlotsWithErrors}
              setIsButtonDisabled={setIsButtonDisabled}
              isButtonDisabled={isButtonDisabled}
              successfulUploads={successfulUploads}
              setSuccessfulUploads={setSuccessfulUploads}
              showUploader={showUploader}
              setShowUploader={setShowUploader}
              context="Onboarding"
            />
          </ScrollArea>
          <Group justify="space-around" grow>
            <UnstyledButton
              disabled={isButtonDisabled}
              onClick={() => {
                completeOnboarding();
                trackEvent('Onboarding', {
                  step: 'import-plots',
                  action: 'skip',
                });
                window.location.replace('map'); // TODO if component moves inside router we can redirect using hooks
              }}
            >
              <Text color="#805BA0" ta="center" fw={500}>
                {t('profile.onboarding.steps.import.buttons.skip')}
              </Text>
            </UnstyledButton>
            <Button
              variant="outline"
              disabled={isButtonDisabled}
              className={classes.button}
              onClick={() => {
                completeOnboarding();
                trackEvent('Onboarding', {
                  step: 'import-plots',
                  action: 'draw-plots',
                });
                window.location.replace('map/draw-plot');
              }}
            >
              {t('profile.onboarding.steps.import.buttons.draw')}
            </Button>
          </Group>
        </Stack>
        <Group justify="space-between">
          <UnstyledButton disabled={isButtonDisabled} onClick={prevStep}>
            <Text color="#805BA0" fw={500}>
              {t('common.previous')}
            </Text>
          </UnstyledButton>
          <Button
            disabled={isButtonDisabled}
            onClick={() => {
              completeOnboarding();
              trackEvent('Onboarding', {
                step: 'import-plots',
                action: 'done',
              });
              window.location.replace('map');
            }}
          >
            {t('common.done')}
          </Button>
        </Group>
      </Flex>
    </GenericErrorMessage>
  );
};
