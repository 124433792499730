import {
  Button,
  Center,
  Group,
  Select,
  Stack,
  Text,
  Title,
} from '@liveeo/component-library';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate, useParams } from 'react-router-dom';
import 'survey-core/defaultV2.min.css';
import { Survey } from 'survey-react-ui';
import { useIntlApi, useNotification } from '../../hooks';
import { useSurvey } from '../../hooks/useSurvey';
import { useSurveyJsModel } from '../../hooks/useSurveyJsModel';
import { useSurveyResponse } from '../../hooks/useSurveyResponse';
import { TemporaryLoadingPage } from './TemporaryLoadingPage';
import { TemporaryPageNotFoundPage } from './TemporaryPageNotFoundPage';

export const EditSurveyResponsePage = () => {
  const { surveyResponseId } = useParams();
  const { t } = useTranslation();
  const {
    data: surveyResponse,
    isLoading: surveyResponseIsLoading,
    updateSurveyResponse,
    submitSurveyResponse,
  } = useSurveyResponse(surveyResponseId);
  const { successfullyUpdated } = useNotification();
  const [country, setCountry] = useState<string>();
  useEffect(() => {
    setCountry(surveyResponse?.countryCode);
  }, [surveyResponse?.countryCode, setCountry]);
  const navigate = useNavigate();
  const { data: survey, isLoading: surveyLoading } = useSurvey(
    surveyResponse?.surveyId
  );
  const surveyModel = useSurveyJsModel(
    survey?.json,
    surveyResponse?.json,
    surveyResponse?.status
  );
  const { localizedCountryList } = useIntlApi();

  const navigateToDocumentsTable = () => navigate('/map/documents?pan=1');
  const save = async () => {
    const surveyResponseJson = surveyModel?.data;
    await updateSurveyResponse.execute({
      json: surveyResponseJson,
      countryCode: country,
    });
  };
  const saveAndClose = async () => {
    await save();
    // TODO TEUDR-1760 fix notifications
    successfullyUpdated('survey response');
    navigateToDocumentsTable();
  };
  const submit = async () => {
    await save();
    await submitSurveyResponse.execute({
      status: 'COMPLETED',
    });
    // TODO TEUDR-1760 fix notifications
    successfullyUpdated('survey response');
    navigateToDocumentsTable();
  };

  if (surveyResponseIsLoading || surveyLoading) return <TemporaryLoadingPage />;
  if (!survey || !surveyResponse) return <TemporaryPageNotFoundPage />;
  if (!surveyModel) return <TemporaryLoadingPage />;

  const isCompleted = surveyResponse.status === 'COMPLETED';

  // TODO TEUDR-1760 make this look nice
  // TODO TEUDR-1760 avoid duplication with CreateSurveyResponsePage
  return (
    <Center>
      <Stack w="100vw">
        <Stack
          w="100vw"
          maw={'calc(90*(var(--sjs-base-unit, var(--base-unit, 8px))))'}
          mx={'auto'}
        >
          <Title ta="center" order={1} size="h2">
            {survey.name}
          </Title>
          <Text fs={'italic'}>{t('documents.create.info')}</Text>
          <Select
            label={t('documents.create.country')}
            placeholder={t<string>('documents.create.country')}
            value={country}
            data={localizedCountryList}
            searchable
            onChange={(val) => setCountry(val ?? undefined)}
            styles={{
              root: { backgroundColor: 'var(--mantine-color-body)' },
              wrapper: { backgroundColor: 'var(--mantine-color-body)' },
              input: { backgroundColor: 'var(--mantine-color-body)' },
              dropdown: { backgroundColor: 'var(--mantine-color-body)' },
            }}
            disabled={isCompleted}
          />
          <Survey model={surveyModel} />
        </Stack>
        <Group justify="flex-end" p="md">
          <Button
            variant="outline"
            w="175px"
            component={Link}
            to={'/map/documents?pan=1'}
          >
            {t('common.close')}
          </Button>
          <Button
            variant="outline"
            w="175px"
            onClick={saveAndClose}
            loading={updateSurveyResponse.isLoading}
            display={isCompleted ? 'none' : undefined}
          >
            {t('common.saveAndClose')}
          </Button>
          <Button
            variant="outline"
            w="175px"
            loading={updateSurveyResponse.isLoading}
            display={isCompleted ? 'none' : undefined}
          >
            {t('documents.create.addAnother')}
          </Button>
          <Button
            variant="outline"
            w="175px"
            loading={updateSurveyResponse.isLoading}
            onClick={submit}
            display={isCompleted ? 'none' : undefined}
          >
            {t('common.submit')}
          </Button>
        </Group>
      </Stack>
    </Center>
  );
};
