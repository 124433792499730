import { kebabCase } from 'lodash';
import { useCallback, useState } from 'react';
// import { SurveyPDF } from 'survey-pdf';
import { SurveyResponse } from '../../../shared/types';

export const printPdf = async (surveyResponse: SurveyResponse) => {
  // TODO enable once we have a proper license for surveyjs pdf
  // const pdfDocOptions: SurveyPDF['options'] = {
  //   fontSize: 12,
  //   readonlyRenderAs: 'text',
  // };
  // const surveyPdf = new SurveyPDF(surveyResponse.survey.json, pdfDocOptions);
  // surveyPdf.data = surveyResponse.json;
  // surveyPdf.mode = 'display';
  // await surveyPdf.save(`${kebabCase(surveyResponse.respondent.name)}.pdf`);
  // surveyPdf.
};

export const useDownloadDocument = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const download = useCallback(
    async (surveyResponse: SurveyResponse) => {
      try {
        setError(false);
        setLoading(true);
        await printPdf(surveyResponse);
      } catch (error) {
        setError(true);
      } finally {
        setLoading(false);
      }
    },
    [setError, setLoading]
  );
  return {
    error,
    loading,
    download,
  };
};
