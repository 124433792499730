import { Beta, Menu } from '@liveeo/component-library';
import { PropsWithChildren } from 'react';
import { Link } from 'react-router-dom';
import classes from './DocumentsActionsMenu.module.css';

type MenuProps = PropsWithChildren & {
  items: {
    title: string;
    href: string;
    key: string;
  }[];
  dropDownPosition?: 'bottom-start' | 'bottom-end';
};

/** Usage: Pass `<Menu.Target><MyTargetComponentOrButtonOrWhatever /></Menu.Target>` as children */
export const CreateSurveyResponseMenu = ({
  items,
  dropDownPosition = 'bottom-start',
  children,
}: MenuProps) => {
  return (
    <Menu position={dropDownPosition}>
      {children}
      <Menu.Dropdown bg="dark.8" className={classes.dropdown}>
        {items.map((item) => (
          <Menu.Item
            className={classes.item}
            component={Link}
            to={item.href}
            key={item.key}
          >
            <Beta.Text>{item.title}</Beta.Text>
          </Menu.Item>
        ))}
      </Menu.Dropdown>
    </Menu>
  );
};
