import { Text as MantineText, TextProps } from '@mantine/core';

const sizeMap = {
  h1: 'lg',
  h2: 'xl',
  b1: 'lg',
  b2: 'md',
  b3: 'sm',
  caption: 'xs',
};

interface CustomTextProps extends Omit<TextProps, 'size'> {
  // @default 'b1'
  variant?: 'h1' | 'h2' | 'b1' | 'b2' | 'b3' | 'caption';
  children: React.ReactNode;
  span?: boolean;
}

const CustomText: React.FC<CustomTextProps> = ({
  variant = 'b1',
  ...props
}) => {
  const fontSize = sizeMap[variant];
  const fontWeight =
    variant === 'caption' || variant === 'h1' || variant === 'h2'
      ? 600
      : undefined;

  return <MantineText fz={fontSize} fw={fontWeight} {...props} />;
};

export { CustomText as Text };
