import { useQuery } from '@tanstack/react-query';
import { Survey } from '../shared/types';
import { useFetchApi } from './useFetchApi';
import { useNotification } from './useNotification';
import { mockSurvey } from './useSurveys';

export const useSurvey = (surveyId?: string) => {
  const fetch = useFetchApi();
  const { apiError } = useNotification();

  const { data, isLoading, isFetching } = useQuery({
    queryKey: ['survey', surveyId],
    queryFn: () => fetch(`surveys/${surveyId}`) as Promise<Survey>,
    // queryFn: fakeSurveyFetch,
    onError: apiError,
    refetchOnWindowFocus: false,
    enabled: !!surveyId,
  });

  return {
    data,
    isLoading,
    isFetching,
  };
};

// TODO TEUDR-1088 remove. currently useful for development without backend
// const fakeSurveyFetch = async () => mockSurvey;
