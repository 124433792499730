import { Menu as MantineMenu, MenuProps } from '@mantine/core';
import classes from './Menu.module.css';

export const Menu = function ({
  additionalClassNames,
  ...props
}: MenuProps & { additionalClassNames?: Record<string, string> }) {
  return (
    <MantineMenu
      {...props}
      radius="xs"
      classNames={{ ...classes, ...additionalClassNames }}
    />
  );
};

Menu.displayName = MantineMenu.displayName;
Menu.Item = MantineMenu.Item;
Menu.Label = MantineMenu.Label;
Menu.Dropdown = MantineMenu.Dropdown;
Menu.Target = MantineMenu.Target;
Menu.Divider = MantineMenu.Divider;
