import { useEffect, useState } from 'react';
import { SidePanelContainer } from './SidePanelContainer';
import { useTranslation } from 'react-i18next';
import { useTracking, useTransactions } from '../../hooks';
import {
  Beta,
  Button,
  DataGrid,
  Modal,
  Title,
} from '@liveeo/component-library';
import { Transaction } from '../../shared/types';
import { FiltersBar } from '../Transactions/Components';
import { useTransactionColumns } from '../Transactions/hooks/useTransactionColumns';
import type { FilterState } from '../Transactions/Components/FiltersBar';

export const TransactionsTablePanel = () => {
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [isImportModalOpen, setIsImportModalOpen] = useState(false);
  const { t } = useTranslation();
  const { data: transactions, isLoading } = useTransactions();
  const isEmpty = !isLoading && !transactions?.length;
  const { trackEvent } = useTracking();
  const columns = useTransactionColumns();

  const [filters, setFilters] = useState<FilterState>();

  const filteredTransactions = transactions?.filter((transaction) => {
    if (filters?.activity && transaction.activityType !== filters.activity) {
      return false;
    }
    if (
      filters?.product &&
      !transaction.components?.some((c) => c.commodity === filters.product)
    ) {
      return false;
    }
    return true;
  });

  const handleFilterChange = (newFilters: Partial<FilterState>) => {
    setFilters((prev) => ({
      ...prev,
      ...newFilters,
    }));
  };

  const toggleImportModal = (boolean: boolean) => {
    if (!isButtonDisabled) setIsImportModalOpen(boolean);
  };

  useEffect(() => {
    if (filters?.sort) {
      // Handle sorting based on field name with optional minus prefix for descending
      const field = filters.sort.startsWith('-')
        ? filters.sort.substring(1)
        : filters.sort;

      const direction = filters.sort.startsWith('-') ? 'desc' : 'asc';
      transactions?.sort((a: Transaction, b: Transaction) => {
        const aValue = a[field as keyof Transaction];
        const bValue = b[field as keyof Transaction];
        if (!aValue || !bValue) return 0;
        if (direction === 'asc') {
          return aValue > bValue ? 1 : -1;
        }
        return aValue < bValue ? 1 : -1;
      });
    }
  }, [filters, transactions]);

  useEffect(() => {
    if (isImportModalOpen) {
      trackEvent('Map', {
        step: 'transaction-creation',
        action: 'init',
      });
    }
  }, [trackEvent, isImportModalOpen]);

  return (
    <>
      <FiltersBar
        onFilterChange={handleFilterChange}
        transactions={transactions || []}
        selectedFilters={filters}
      />
      <SidePanelContainer panelSizeClass="panel-size-lg-offset-top">
        <SidePanelContainer.Header>
          <SidePanelContainer.Title title={t('transactions.title')} />
          <Button
            variant="transparent"
            size="sm"
            onClick={() => toggleImportModal(true)}
          >
            <Beta.Icon icon="plus" />
          </Button>
        </SidePanelContainer.Header>
        {isLoading ? (
          <p>Loading</p>
        ) : isEmpty ? (
          <p>Empty table</p>
        ) : (
          <DataGrid
            data={filteredTransactions || []}
            columns={columns}
            onSelect={(id) => console.log('Selected:', id)}
            onSort={(sort: string | number | symbol) => {
              handleFilterChange({ ...filters, sort: sort?.toString() });
            }}
            sortStatus={filters?.sort || ''}
          />
        )}
      </SidePanelContainer>
      <Modal
        size={500}
        opened={isImportModalOpen}
        onClose={() => toggleImportModal(false)}
        title={<Title order={3}>{t('transactions.creation.title')}</Title>}
        centered
      >
        Transaction placeholder for next implementation
      </Modal>
    </>
  );
};
