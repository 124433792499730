import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useFetchApi } from './';
import { AddPlot, MostRecentAnalysis, Plot } from '../shared/types';
import { useCallback, useMemo } from 'react';
import { useNotification } from './useNotification';
import { useLocation, useNavigate } from 'react-router-dom';
import { deforestationRiskMapping } from '../helper';
import { usePlotsContext } from '../contexts/PlotsContext';
import poll from '../helper/poll';

type GetAnalysesResultParams = {
  plotId: string;
  name: string;
};

export const usePlots = () => {
  const { data, error, isLoading } = usePlotsContext();
  const fetch = useFetchApi();
  const queryClient = useQueryClient();
  const { apiError, successfullyCreated } = useNotification();
  const navigate = useNavigate();
  const location = useLocation();

  const getAnalysesResult = async ({
    plotId,
    name,
  }: GetAnalysesResultParams) => {
    const analysesProccessing = await fetch(`plots/${plotId}/analyses`, {
      method: 'POST',
      body: JSON.stringify({
        name,
        type: 'EUFOROBS',
        metadata: {},
      }),
    });

    const analysesResult = await poll<Promise<MostRecentAnalysis>>({
      fn: () => fetch(`analyses/${analysesProccessing?.id}`),
      validate: (result) => typeof result?.riskOfDeforestation === 'number',
    });

    return analysesResult;
  };

  const createPlot = useMutation({
    mutationKey: ['plots'],
    mutationFn: (payload: AddPlot) =>
      fetch('plots', {
        method: 'POST',
        body: JSON.stringify(payload),
      }),
    onSuccess: async (plot: any) => {
      successfullyCreated('plot');
      queryClient.setQueryData(
        ['plots'],
        (prevPlots: Plot[] | undefined) => prevPlots && [plot, ...prevPlots]
      );
      const analysesResult = (await getAnalysesResult({
        plotId: plot.id,
        name: plot.name,
      })) as MostRecentAnalysis;

      const plotWithAnalysis = {
        ...plot,
        mostRecentAnalysesByType: { [analysesResult?.type]: analysesResult },
      };

      queryClient.setQueryData(['plot'], plotWithAnalysis);
      queryClient.setQueryData(['plots'], (prevPlots: Plot[] | undefined) => [
        plotWithAnalysis,
        ...(prevPlots?.slice(1) as Plot[]),
      ]);

      navigate(
        {
          pathname: `/map/plots/edit-plot/${plot.id}`,
          search: location.search,
        },
        { replace: true }
      );
    },
    onError: apiError,
  });

  const findPlotById = useCallback(
    (plotId: string) => {
      if (!plotId) return;
      const plot = data?.find((plot: Plot) => plot.id === plotId);
      queryClient.setQueryData(['plot'], plot);
      return plot;
    },
    [data, queryClient]
  );

  const plotsAsFeatureCollection = useMemo(() => {
    return {
      type: 'FeatureCollection',
      features: data?.map((plot: Plot, i: number) => ({
        id: i, // Mapbox requires the feture id to be a number so we can't use the plotId
        type: 'Feature',
        geometry: plot.geometry,
        properties: {
          plotId: plot.id,
          riskOfDeforestation: deforestationRiskMapping(
            plot?.mostRecentAnalysesByType?.PRECISION?.riskOfDeforestation ??
              plot?.mostRecentAnalysesByType?.EUFOROBS?.riskOfDeforestation
          ),
        },
      })),
    };
  }, [data]);

  return {
    createPlot,
    data,
    error,
    findPlotById,
    isLoading,
    plotsAsFeatureCollection,
    getAnalysesResult,
  };
};
