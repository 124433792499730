import { FeatureFlags } from '../shared/types';

export const featureFlags: FeatureFlags = {
  COMMERCIAL_GRAPH_v2: {
    dev: false,
    staging: false,
    prod: false,
  },
  PRECISION_ANALYSIS: {
    dev: true,
    staging: false,
    prod: false,
  },
  SURVEY: {
    dev: false,
    staging: false,
    prod: false,
  },
  TRANSACTIONS: {
    dev: true,
    staging: false,
    prod: false,
  },
};
