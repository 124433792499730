import { Beta, Button, Flex, Menu } from '@liveeo/component-library';
import {
  ActivityType,
  Transaction,
  TransactionStatus,
} from '../../../shared/types';
import { useTranslation } from 'react-i18next';
import classes from '../../../shared/styles/Menu.module.css';

export interface FilterState {
  sort?: string;
  search?: string;
  title?: string;
  activity?: ActivityType;
  product?: string;
  status?: TransactionStatus;
}

interface FiltersBarProps {
  onFilterChange: (filters: Partial<FilterState>) => void;
  transactions: Transaction[];
  selectedFilters?: Partial<FilterState>;
}
const flexStyle = { top: '20px', left: 0, right: 0 };

export const FiltersBar: React.FC<FiltersBarProps> = ({
  onFilterChange,
  transactions,
  selectedFilters,
}) => {
  const { t } = useTranslation();

  const activity = [...new Set(transactions?.map((t) => t.activityType))];
  const product = [
    ...new Set(
      transactions?.flatMap((t) => t.components?.map((c) => c.commodity) || [])
    ),
  ];
  const status = [...new Set(transactions?.map((t) => t.status))];
  return (
    <Flex
      justify="center"
      align="center"
      gap="xs"
      pos="absolute"
      style={flexStyle}
    >
      <Menu offset={2} position="bottom-start" additionalClassNames={classes}>
        <Menu.Target>
          <Button
            c={selectedFilters?.activity ? 'primary' : undefined}
            size="sm"
            variant={selectedFilters?.activity ? undefined : 'outline'}
          >
            {t(
              selectedFilters?.activity ?? 'transactions.filters.activity.title'
            )}{' '}
            <Beta.Icon icon="chevron-down" />
          </Button>
        </Menu.Target>
        <Menu.Dropdown bg="dark.9" opacity={0.9}>
          <Menu.Item onClick={() => onFilterChange({ activity: undefined })}>
            {t('transactions.filters.activity.all')}
          </Menu.Item>
          {activity.map((activity) => (
            <Menu.Item
              key={activity}
              onClick={() => onFilterChange({ activity })}
            >
              {t(`transactions.activity.${activity}`)}
            </Menu.Item>
          ))}
        </Menu.Dropdown>
      </Menu>
      <Menu position="bottom-start">
        <Menu.Target>
          <Button
            c={selectedFilters?.product ? 'primary' : undefined}
            size="sm"
            variant={selectedFilters?.product ? undefined : 'outline'}
          >
            {t(
              selectedFilters?.product ?? 'transactions.filters.product.title'
            )}{' '}
            <Beta.Icon icon="chevron-down" />
          </Button>
        </Menu.Target>
        <Menu.Dropdown>
          <Menu.Item onClick={() => onFilterChange({ product: undefined })}>
            {t('transactions.filters.product.all')}
          </Menu.Item>
          {product.map((product) => (
            <Menu.Item
              key={product}
              onClick={() => onFilterChange({ product })}
            >
              {product}
            </Menu.Item>
          ))}
        </Menu.Dropdown>
      </Menu>
      <Menu position="bottom-start">
        <Menu.Target>
          <Button
            c={selectedFilters?.status ? 'primary' : undefined}
            size="sm"
            variant={selectedFilters?.status ? undefined : 'outline'}
          >
            {t(selectedFilters?.status ?? 'transactions.filters.status.title')}{' '}
            <Beta.Icon icon="chevron-down" />
          </Button>
        </Menu.Target>
        <Menu.Dropdown>
          <Menu.Item onClick={() => onFilterChange({ status: undefined })}>
            {t('transactions.filters.status.all')}
          </Menu.Item>
          {status.map((status) => (
            <Menu.Item key={status} onClick={() => onFilterChange({ status })}>
              {status}
            </Menu.Item>
          ))}
        </Menu.Dropdown>
      </Menu>
    </Flex>
  );
};
