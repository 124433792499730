import { useFetchApi } from './useFetchApi';
import { useNotification } from './useNotification';
import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import { Business, Client } from '../shared/types';

export const useBusiness = (businessId?: string) => {
  const fetch = useFetchApi();
  const queryClient = useQueryClient();
  const { apiError } = useNotification();

  const { data, isLoading, isError } = useQuery<Business>({
    queryKey: ['business', businessId],
    queryFn: () =>
      fetch(
        `businesses/${businessId}?includes=machineToMachineClient`
      ) as Promise<Business>,
    onError: () => {
      apiError();
    },
    enabled: !!businessId,
  });

  const updateMutation = useMutation({
    mutationKey: ['business', businessId],
    mutationFn: (payload: Partial<Business>) =>
      fetch(`businesses/${businessId}`, {
        method: 'PATCH',
        body: JSON.stringify(payload),
      }),
  });

  const updateBusiness = (payload: Partial<Business>) =>
    updateMutation.mutateAsync(payload, {
      onSuccess: (business) => {
        queryClient.setQueryData(['business', businessId], business);
        queryClient.invalidateQueries({ queryKey: ['business', businessId] });
      },
      onError: () => apiError(),
    });

  // The machine to machine client is created by sending an empty object to the machine to machine client controller. Since it is a relation of the business entity,
  // we must first create the client, extract the client id from the returned object after succeful creation, update the cached business object, and invalidate
  // the business query for a smooth user experience

  const createMutation = useMutation({
    mutationKey: ['business', businessId],
    mutationFn: (payload: Record<string, never>) =>
      fetch('auth/machine-to-machine-clients', {
        method: 'POST',
        body: JSON.stringify(payload),
      }),
  });

  const createClient = (payload: Record<string, never>) =>
    createMutation.mutateAsync(payload, {
      onSuccess: (data) => {
        const { clientId } = data as Client;
        queryClient.setQueryData(['business', businessId], (business: any) => ({
          ...business,
          machineToMachineClientId: clientId,
        }));
      },
      onSettled: () => {
        queryClient.invalidateQueries({ queryKey: ['business', businessId] });
      },
      onError: () => apiError(),
    });

  return { data, isLoading, isError, updateBusiness, createClient };
};
