import { Text } from '@liveeo/component-library';
import { Link } from 'react-router-dom';
import { useUser } from '../../../hooks';
import { SurveyResponse } from '../../../shared/types';
import classes from './DocumentsTableColumns.module.css';
import { useTranslation } from 'react-i18next';

export const OwnerCell = ({
  surveyResponse,
  text,
}: {
  surveyResponse: SurveyResponse;
  text: string | null | number;
}) => {
  const user = useUser();
  const { t } = useTranslation();
  const businessId = surveyResponse.respondent?.id;
  const myBusinessId = user.data?.businessId;
  if (!myBusinessId || myBusinessId === businessId) {
    return <Text>{t('documents.table.values.owner.me')}</Text>;
  }
  const href = `/map/suppliers/${businessId}?pan=1`;
  return (
    <Text component={Link} to={href} className={classes.link}>
      {text}
    </Text>
  );
};
