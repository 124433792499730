import { useQuery } from '@tanstack/react-query';
import { useNotification } from './useNotification';
import { useFetchApi } from './useFetchApi';
import { Transaction } from '../shared/types';

export const useTransactions = () => {
  const fetch = useFetchApi();
  const { apiError } = useNotification();

  const { data, isLoading, isFetching } = useQuery({
    queryKey: ['transactions'],
    queryFn: () =>
      fetch(
        'transactions?includes=components.supplierBusiness,components.ddsReferences'
      ) as Promise<Transaction[]>,
    onError: apiError,
    refetchOnWindowFocus: false,
  });

  return {
    data,
    isLoading,
    isFetching,
  };
};
