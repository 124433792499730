import { Routes, Route } from 'react-router-dom';
import { GenericErrorFallback } from '../../shared/components/GenericErrorFallback';
import { PlotsTablePanel } from './PlotsTablePanel';
import { SuppliersTablePanel } from './SuppliersTablePanel';
import { SupplierDetailPanel } from '../Suppliers/SupplierDetailPanel';
import { BuyersTablePanel } from './BuyersTablePanel';
import { BuyerDetailPanel } from '../Buyers/BuyerDetailPanel';
import { NotificationsPanel } from './NotificationsPanel';
import { ReceivedInvitations } from '../Notifications/ReceivedInvitations';
import { SentInvitations } from '../Notifications/SentInvitations';
import { DocumentsTablePanel } from './DocumentsTablePanel';
import { TransactionsTablePanel } from './TransactionsTablePanel';
import { useFlags } from '../../contexts/FlagsContext';

export const SideNavRoutes = () => {
  const { TRANSACTIONS } = useFlags();
  return (
    <Routes>
      <Route index element={null} />
      <Route
        path="plots/*"
        element={<PlotsTablePanel />}
        errorElement={<GenericErrorFallback />}
      />
      <Route
        path="suppliers"
        element={<SuppliersTablePanel />}
        errorElement={<GenericErrorFallback />}
      />
      <Route
        path="suppliers/:supplierId"
        element={<SupplierDetailPanel />}
        errorElement={<GenericErrorFallback />}
      />
      <Route
        path="buyers"
        element={<BuyersTablePanel />}
        errorElement={<GenericErrorFallback />}
      />
      <Route
        path="buyers/:buyerId"
        element={<BuyerDetailPanel />}
        errorElement={<GenericErrorFallback />}
      />
      {TRANSACTIONS && (
        <Route
          path="transactions"
          element={<TransactionsTablePanel />}
          errorElement={<GenericErrorFallback />}
        />
      )}

      <Route
        path="notifications"
        element={<NotificationsPanel />}
        errorElement={<GenericErrorFallback />}
      >
        <Route index element={<ReceivedInvitations />} />
        <Route path="sent" element={<SentInvitations />} />
      </Route>
      <Route
        path="documents"
        element={<DocumentsTablePanel />}
        errorElement={<GenericErrorFallback />}
      />
    </Routes>
  );
};
