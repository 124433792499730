import { useCallback, useMemo, useRef, useState } from 'react';
import {
  BooleanParam,
  NumberParam,
  QueryParamProvider,
  StringParam,
} from 'use-query-params';
import MapboxDraw from '@mapbox/mapbox-gl-draw';
import type { FeatureCollection } from 'geojson';
import { Flex } from '@liveeo/component-library';
import { InteractiveMap } from './InteractiveMap';
import { MapProvider } from 'react-map-gl';
import { PlotRoutes } from './PlotRoutes';
import {
  OpenSourceParam,
  PlotsParam,
  PrecisionParam,
  usePlotState,
  useToggleAside,
} from '../../hooks';
import { SIMPLE_SELECT } from '../../common/constants';
import { PlotsProvider } from '../../contexts/PlotsContext';
import { SideNavRoutes } from './SideNavRoutes';

export const Map = () => {
  const state = usePlotState();
  const [features, setFeatures] = useState<FeatureCollection | any>({});
  const { isAsideOpen } = useToggleAside();

  const draw = useRef(
    new MapboxDraw({
      displayControlsDefault: true,
    })
  );

  const onDrawPlot = useCallback((e: any) => {
    setFeatures(e.features[0]);
  }, []);

  const onDeletePlot = useCallback(() => {
    draw.current.deleteAll();
    draw.current.changeMode(state?.mode || SIMPLE_SELECT);
    setFeatures({});
  }, [state]);

  const queryParams = useMemo(
    () => ({
      desc: BooleanParam,
      ex: NumberParam,
      gFC2020: OpenSourceParam,
      la: NumberParam,
      ln: NumberParam,
      pan: BooleanParam,
      pg: NumberParam,
      plots: PlotsParam,
      precision: PrecisionParam,
      sel: StringParam,
      sort: StringParam,
      z: NumberParam,
    }),
    []
  );

  const memoizedFeatures = useMemo(() => features, [features]);

  return (
    <QueryParamProvider
      options={{
        params: queryParams,
        removeDefaultsFromUrl: false,
        updateType: 'replaceIn',
      }}
    >
      <PlotsProvider>
        <MapProvider>
          <Flex>
            <InteractiveMap
              draw={draw}
              onDrawPlot={onDrawPlot}
              onDeletePlot={onDeletePlot}
              state={state}
            />
            {isAsideOpen && <SideNavRoutes />}
            <PlotRoutes
              draw={draw}
              features={memoizedFeatures}
              onDeletePlot={onDeletePlot}
            />
          </Flex>
        </MapProvider>
      </PlotsProvider>
    </QueryParamProvider>
  );
};
