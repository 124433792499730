import { useEffect, useState } from 'react';
import { Model } from 'survey-core';
import { surveyJsThemeDarkBorderlessPanelless } from '../theme/surveyjs-theme';
import { SurveyResponseStatus } from '../shared/types';

/**
 * Be aware that surveyjs is going to modify the model object!
 */
export const useSurveyJsModel = (
  surveyJson: Record<PropertyKey, unknown> | undefined,
  surveyResponseJson?: Record<PropertyKey, unknown>,
  surveyResponseStatus?: SurveyResponseStatus
) => {
  // warning: surveyjs isn't using the ReactJS way of changing the referencing. The surveyModel object reference will not change but somehow surveyjs manages to rerender correctly anyway. The alternative of using useRef didn't work because it was not rendering the final model, except we forced a rerender.
  const [surveyModel, setSurveyModel] = useState<Model | null>(null);
  useEffect(() => {
    if (!surveyJson) return;
    const model = new Model(surveyJson);
    model.showCompleteButton = false;
    if (surveyResponseStatus === 'COMPLETED') model.mode = 'display';
    model.applyTheme(surveyJsThemeDarkBorderlessPanelless);
    if (surveyResponseJson) model.data = surveyResponseJson;
    setSurveyModel(model);
  }, [surveyJson, surveyResponseJson, surveyResponseStatus]);
  return surveyModel;
};
