import { PropsWithChildren } from 'react';
import { Link } from 'react-router-dom';
import {
  Divider,
  Group,
  Title,
  UnstyledButton,
  Icon,
} from '@liveeo/component-library';
import classes from './SidePanelContainer.module.css';

type TitleWithNavProps = {
  path: string;
  name: string;
};

type TitleProps = {
  title: string;
};

type HeaderProps = {
  justify?: 'flex-start' | 'space-between';
};

const Header = ({
  children,
  justify = 'space-between',
}: PropsWithChildren<HeaderProps>) => (
  <Group justify={justify} className={classes['header']}>
    {children}
  </Group>
);

const _Title = ({ title }: TitleProps) => (
  <Group>
    <Title order={3} c="dark.0">
      {title}
    </Title>
    <Divider orientation="vertical" />
  </Group>
);

const TitleWithNav = ({ path, name }: TitleWithNavProps) => (
  <UnstyledButton component={Link} to={path}>
    <Group>
      <Icon icon="arrow-left" size="lg" />
      <Title order={3} c="dark.0">
        {name}
      </Title>
    </Group>
  </UnstyledButton>
);

type SidePanelContainerProps = PropsWithChildren<{
  panelSizeClass?:
    | 'panel-size-sm'
    | 'panel-size-lg'
    | 'panel-size-lg-offset-top';
}>;

export const SidePanelContainer = ({
  children,
  panelSizeClass = 'panel-size-sm',
}: SidePanelContainerProps) => (
  <div
    className={`${classes.root} ${classes['side-panel']} ${classes[panelSizeClass]}`}
  >
    {children}
  </div>
);

SidePanelContainer.Header = Header;
SidePanelContainer.Title = _Title;
SidePanelContainer.TitleWithNav = TitleWithNav;
