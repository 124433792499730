import {
  Button,
  Center,
  Group,
  Select,
  Stack,
  Text,
  Title,
} from '@liveeo/component-library';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useParams } from 'react-router-dom';
import 'survey-core/defaultV2.min.css';
import { Survey } from 'survey-react-ui';
import { useIntlApi } from '../../hooks';
import { useSurvey } from '../../hooks/useSurvey';
import { useSurveyJsModel } from '../../hooks/useSurveyJsModel';
import { useSurveyResponses } from '../../hooks/useSurveyResponses';
import { TemporaryLoadingPage } from './TemporaryLoadingPage';
import { TemporaryPageNotFoundPage } from './TemporaryPageNotFoundPage';

export const CreateSurveyResponsePage = () => {
  const { surveyId } = useParams();
  const { data: survey, isLoading } = useSurvey(surveyId);
  const { t } = useTranslation();
  const { createSurveyResponse } = useSurveyResponses(surveyId);
  const [country, setCountry] = useState<string>();
  const surveyModel = useSurveyJsModel(survey?.json);
  const { localizedCountryList } = useIntlApi();

  const save = () => {
    if (!surveyModel) return;
    const surveyResponseJson = surveyModel.data;
    // Note: this will navigate away on success
    createSurveyResponse.execute({
      json: surveyResponseJson,
      countryCode: country ?? undefined,
    });
  };

  if (isLoading || !surveyModel) return <TemporaryLoadingPage />;
  if (!survey) return <TemporaryPageNotFoundPage />;
  if (!surveyModel) return <TemporaryLoadingPage />;

  // TODO TEUDR-1696 make this look nice
  return (
    <Center>
      <Stack w="100vw">
        <Stack
          w="100vw"
          maw={'calc(90*(var(--sjs-base-unit, var(--base-unit, 8px))))'}
          mx={'auto'}
        >
          <Title ta="center" order={1} size="h2">
            {survey.name}
          </Title>
          <Text fs={'italic'}>{t('documents.create.info')}</Text>
          <Select
            label={t('documents.create.country')}
            placeholder={t<string>('documents.create.country')}
            data={localizedCountryList}
            searchable
            onChange={(val) => setCountry(val ?? undefined)}
            styles={{
              root: { backgroundColor: 'var(--mantine-color-body)' },
              wrapper: { backgroundColor: 'var(--mantine-color-body)' },
              input: { backgroundColor: 'var(--mantine-color-body)' },
              dropdown: { backgroundColor: 'var(--mantine-color-body)' },
            }}
          />
          <Survey model={surveyModel} />
        </Stack>

        <Group justify="flex-end" p="md">
          <Button
            variant="outline"
            w="175px"
            component={Link}
            to={'/map/documents?pan=1'}
          >
            {t('common.close')}
          </Button>
          <Button
            variant="outline"
            w="175px"
            onClick={save}
            loading={createSurveyResponse.isLoading}
          >
            {t('common.saveAndClose')}
          </Button>
          <Button
            variant="outline"
            w="175px"
            loading={createSurveyResponse.isLoading}
          >
            {t('documents.create.addAnother')}
          </Button>
          <Button
            variant="outline"
            w="175px"
            loading={createSurveyResponse.isLoading}
          >
            {t('common.submit')}
          </Button>
        </Group>
      </Stack>
    </Center>
  );
};
