import { Beta, Button, DataGrid, Menu, Stack } from '@liveeo/component-library';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { heightOfSidePanelContent } from '../../helper/side-panel-height';
import { useTracking } from '../../hooks';
import { useSurveyResponses } from '../../hooks/useSurveyResponses';
import { useSurveys } from '../../hooks/useSurveys';
import { Table } from '../../shared/components/Table';
import { Survey, SurveyResponse } from '../../shared/types';
import { DocumentIcon } from '../Documents/components/DocumentIcon';
import { CreateSurveyResponseMenu } from '../Documents/CreateSurveyResponseMenu';
import { useDocumentsTableColumns } from '../Documents/hooks/useDocumentsTableColumns';
import { SidePanelContainer } from './SidePanelContainer';

const Text = Beta.Text;

const EmptyTable = ({ surveys }: { surveys: Survey[] }) => {
  const columns = useDocumentsTableColumns();
  const { t } = useTranslation();

  return (
    <Stack>
      <DataGrid data={[]} columns={columns} />
      <Stack align="center" pt={80}>
        <DocumentIcon />
        <Text fw="bold">{t('documents.table.empty.noData')}</Text>
        <Text>{t('documents.table.empty.info')}</Text>
        <CreateSurveyResponseMenu
          items={surveys.map((survey) => ({
            href: `/documents/new/${survey.id}`,
            title: t('documents.table.createNewSurveyResponse', {
              name: survey.name,
            }),
            key: survey.id,
          }))}
        >
          <Menu.Target>
            <Button variant="outline">
              {t('documents.table.empty.callToAction')}
            </Button>
          </Menu.Target>
        </CreateSurveyResponseMenu>
      </Stack>
    </Stack>
  );
};

export const DocumentsTablePanel = () => {
  const { t } = useTranslation();
  const { trackEvent } = useTracking();
  const { data: surveys } = useSurveys();
  const { data: surveyResponses, isLoading: isLoadingSurveysResponses } =
    useSurveyResponses();
  const columns = useDocumentsTableColumns();
  const isEmpty = !isLoadingSurveysResponses && !surveyResponses?.length;

  useEffect(() => {
    trackEvent('Documents', {
      step: 'documents-page',
      action: 'open',
    });
  }, [trackEvent]);

  return (
    <SidePanelContainer panelSizeClass="panel-size-lg">
      <SidePanelContainer.Header>
        <SidePanelContainer.Title title={t('documents.table.title')} />
        <CreateSurveyResponseMenu
          items={
            surveys?.map((survey) => ({
              title: t('documents.table.createNewSurveyResponse', {
                name: survey.name,
              }),
              href: `/documents/new/${survey.id}`,
              key: survey.id,
            })) ?? []
          }
          dropDownPosition="bottom-end"
        >
          <Menu.Target>
            <Button variant="transparent" px="xs">
              <Beta.Icon icon="plus" />
            </Button>
          </Menu.Target>
        </CreateSurveyResponseMenu>
      </SidePanelContainer.Header>

      {isLoadingSurveysResponses ? (
        <Table.Loader height={heightOfSidePanelContent} />
      ) : isEmpty ? (
        <EmptyTable surveys={surveys ?? []} />
      ) : (
        <Stack>
          <DataGrid
            data={surveyResponses ?? []}
            columns={columns}
            sortStatus={''}
            keyExtractor={(row: SurveyResponse) => row.id}
          />
        </Stack>
      )}
    </SidePanelContainer>
  );
};
